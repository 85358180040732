/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class TermOfUseOwner extends React.Component {
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <>
                <DemoNavbar />
                <main className="profile-page" ref="main">
                    <section className="section-profile-cover section-shaped my-0">
                        {/* Circles background */}
                        <div className="shape shape-style-1 shape-default alpha-4"></div>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </section>
                    <section className="section">
                        <Container>
                            <Card className="card-profile shadow mt--300">
                                <div className="px-4">
                                    <Row className="justify-content-center">
                                        {/* <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={require("assets/img/theme/team-4-800x800.jpg")}
                          />
                        </a>
                      </div>
                    </Col> */}
                                        <Col
                                            className="order-lg-3 text-lg-right align-self-lg-center"
                                            lg="4"
                                        >
                                            <div className="card-profile-actions py-4 mt-lg-0">
                                                <Button
                                                    className="mr-4"
                                                    color="info"
                                                    href="#pablo"
                                                    onClick={(e) =>
                                                        e.preventDefault()
                                                    }
                                                    size="sm"
                                                >
                                                    Proprietários
                                                </Button>
                                                <Button
                                                    className="float-right"
                                                    color="default"
                                                    href="#pablo"
                                                    onClick={(e) =>
                                                        e.preventDefault()
                                                    }
                                                    size="sm"
                                                >
                                                    Organizadores
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col className="order-lg-1" lg="4">
                                            <div className="card-profile-stats d-flex justify-content-center">
                                                <div>
                                                    <span className="heading">
                                                        21/01/2022 12:51
                                                    </span>
                                                    <span className="description">
                                                        Última atualização
                                                    </span>
                                                </div>
                                                {/* <div>
                          <span className="heading">10</span>
                          <span className="description">Photos</span>
                        </div>
                        <div>
                          <span className="heading">89</span>
                          <span className="description">Comments</span>
                        </div> */}
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="text-center mt-5">
                                        <h3>
                                            Termos de Uso - Proprietário e
                                            Organizadores
                                        </h3>
                                        {/* <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Proprietário
                    </div> */}
                                        {/* <div className="h6 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Proprietário
                    </div> */}
                                        {/* <div>
                      <i className="ni education_hat mr-2" />
                      University of Computer Science
                    </div> */}
                                    </div>
                                    <div className="mt-5 py-5 border-top ">
                                        <Row className="justify-content-center">
                                            <Col lg="9">
                                                <br />
                                                <p class="h4">Glossário:</p>
                                                <p>
                                                    <div>
                                                        Usuário: Termo utilizado
                                                        para denominar
                                                        Consumidores ou
                                                        Proprietários com
                                                        cadastro ativo que
                                                        utilizam a plataforma
                                                        nPass.
                                                    </div>
                                                    <br />
                                                    <div>
                                                        Proprietário: Termo
                                                        utilizado para denominar
                                                        pessoas (físicas ou
                                                        jurídicas) que tem como
                                                        objetivo criar e
                                                        gerenciar acessos,
                                                        através da Plataforma
                                                        nPass e divulgar a
                                                        Consumidores.
                                                    </div>
                                                    <br />
                                                    <div>
                                                        Consumidor: Termo
                                                        utilizado para denominar
                                                        pessoas que adquirem
                                                        Passports garantindo
                                                        acesso em determinado
                                                        nPass criado.
                                                    </div>
                                                    <br />
                                                    <div>
                                                        Passports: Termo
                                                        utilizado para denominar
                                                        o documento digital,
                                                        adquirido de forma paga
                                                        ou gratuita, contendo
                                                        informações necessárias
                                                        que permite o acesso a
                                                        determinado nPass
                                                        criado.
                                                    </div>
                                                    <br />
                                                    <div>
                                                        Passports Host: Termo
                                                        utilizado para denominar
                                                        o documento digital,
                                                        adquirido de forma paga
                                                        ou gratuita, contendo
                                                        informações necessárias
                                                        que permite o acesso a
                                                        determinado nPass
                                                        criado, para não
                                                        proprietário da conta.
                                                    </div>{" "}
                                                    <br />
                                                    <div>
                                                        Plataforma nPass: É uma
                                                        ferramenta online mobile
                                                        que tem como objetivo
                                                        auxiliar Proprietários,
                                                        na gestão de acessos,
                                                        com objetivo de auxiliar
                                                        no controle das vendas
                                                        para os nPass, através
                                                        de Passports.
                                                    </div>
                                                    <br />
                                                    <div>
                                                        nPass: O termo refere-se
                                                        aos meios que necessitam
                                                        de Controle de Acesso
                                                        criado pelos
                                                        Proprietários.
                                                    </div>{" "}
                                                    <br />
                                                    <div>
                                                        Administrador: Termo
                                                        utilizado para denominar
                                                        pessoas (físicas ou
                                                        jurídicas) que recebeu
                                                        acesso pelo Proprietário
                                                        para ação de Check-in e
                                                        Check-out em determinado
                                                        nPass.
                                                    </div>{" "}
                                                    <br />
                                                    <div>
                                                        Confirmado: Pessoal que
                                                        adquiriu o acesso a
                                                        determinado nPass
                                                        através do Passport,
                                                        sendo ele pago ou não.
                                                    </div>{" "}
                                                    <br />
                                                    <div>
                                                        Check-in: Ato na
                                                        plataforma de ler, via
                                                        camera, o Passport
                                                        contendo QR-Code único
                                                        de acesso do Confirmado,
                                                        dando opção, ao
                                                        Proprietário ou
                                                        Administrador do nPass,
                                                        inserir o Consumidor na
                                                        lista de internos do
                                                        nPass.
                                                    </div>{" "}
                                                    <br />
                                                    <div>
                                                        Check-out: Ato na
                                                        plataforma do
                                                        Proprietário ou
                                                        Administrador, em
                                                        colocar o Consumidor na
                                                        lista de externo em
                                                        determinado nPass.
                                                    </div>
                                                    <div>
                                                        Controle de Acessos: É o
                                                        controle que o
                                                        proprietário ou
                                                        administrador tem de
                                                        quem entrou ou ainda vai
                                                        entrar no local de
                                                        realização do nPass
                                                        através do acesso a
                                                        lista de Consumidores.{" "}
                                                    </div>{" "}
                                                    <br />
                                                    <div>
                                                        Flirt: É um termo
                                                        referente a uma
                                                        funcionalidade dentro da
                                                        plataforma nPass que tem
                                                        por objetivo conectar os
                                                        usuários confirmados no
                                                        nPass e promover
                                                        comunicação entre eles.{" "}
                                                    </div>
                                                    <div>
                                                        Reações: É um termo
                                                        utilizado para se
                                                        referir as ações e
                                                        reações dos usuários aos
                                                        nPass criados. Por
                                                        exemplo, mostrar
                                                        Interesse, compartilhar
                                                        ou confirmar presença no
                                                        nPass publicado.
                                                    </div>{" "}
                                                    <br />
                                                    <div>
                                                        Plataforma Stripe:
                                                        Plataforma de
                                                        pagamentos, intermediário
                                                        financeiro, utilizada
                                                        pela plataforma nPass
                                                        para gerenciar
                                                        pagamento, de uso e
                                                        acesso exclusivamente do
                                                        Proprietário.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    1. Aceitação do Termo De Uso
                                                    e Política De Privacidade
                                                </p>
                                                <p>
                                                    Ao acessar e utilizar os
                                                    serviços disponibilizados
                                                    pela plataforma nPass, o
                                                    usuário confirma que leu,
                                                    compreendeu e concordou com
                                                    o Termo de Uso e a Política
                                                    de Privacidade aplicáveis à
                                                    ferramenta.
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    2. Quem somos nós?
                                                </p>
                                                <p>
                                                    A plataforma nPass é um
                                                    serviço mobile, disponível
                                                    nas lojas Play Store e App
                                                    Store, criada para AUXILIAR
                                                    os Proprietários na gestão e
                                                    no Controle de Acessos em
                                                    determinados locais através
                                                    de Passports, grátis ou
                                                    pago. Auxilia nas operações
                                                    de vendas utilizando o
                                                    Intermediador financeiro
                                                    Stripe, e os conecta aos
                                                    Consumidores através de
                                                    ferramentas de rede de
                                                    interação.
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    3. Para o conhecimento do
                                                    Proprietário:
                                                </p>
                                                <p>
                                                    <div>
                                                        a. Não somos
                                                        responsáveis ou
                                                        co-organizadores dos
                                                        nPass criados, a
                                                        responsabilidade do
                                                        acontecimento e
                                                        organização em geral dos
                                                        nPass é do Proprietário
                                                        que o criou. A
                                                        plataforma nPass tem
                                                        como objetivo auxiliar o
                                                        Proprietário no
                                                        gerenciamento.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        b. Não somos
                                                        responsáveis pelo o que
                                                        acontece no nPass, é
                                                        responsabilidade do
                                                        Proprietário.
                                                    </div>
                                                    <b />
                                                    <div>
                                                        <br />
                                                        c. Após a conclusão do
                                                        pagamento, o Consumidor
                                                        terá acesso ao Passport
                                                        do nPass criado, com
                                                        possibilidade de
                                                        impressão, e o mesmo
                                                        garantirá acesso ao
                                                        nPass organizado. É de
                                                        responsabilidade do
                                                        Proprietário garantir o
                                                        acesso ao nPass ao
                                                        Consumidor, conforme as
                                                        informações prestadas no
                                                        Passport na hora da
                                                        compra. Caso venha
                                                        falhar no serviço
                                                        prestado, poderá
                                                        responder judicialmente.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        d. É de obrigação do
                                                        Proprietário dar suporte
                                                        via email ao Consumidor,
                                                        como disponibilizar nota
                                                        fiscal caso solicitado,
                                                        emitir reembolsos,
                                                        informar cancelamentos,
                                                        entre outros.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        e. Será necessário, e de
                                                        obrigação do
                                                        Proprietário, a
                                                        integração/cadastro do
                                                        mesmo na plataforma do
                                                        Intermediador Stripe pra
                                                        ter controle e acesso a
                                                        ações financeira dos
                                                        nPass organizados, como
                                                        reembolsos, repasses e
                                                        outros.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        f. A plataforma nPass
                                                        não é uma plataforma de
                                                        pagamento.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        g. A plataforma nPass
                                                        poderá sofrer alterações
                                                        em necessidades de
                                                        correções.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        h. A plataforma nPass se
                                                        compromete a adotar as
                                                        melhores posturas para
                                                        evitar incidentes de
                                                        segurança. Contudo, é
                                                        necessário destacar que
                                                        nenhuma tecnologia é
                                                        inteiramente segura e
                                                        livre de riscos. É
                                                        possível que, apesar de
                                                        todos os nossos
                                                        protocólos de segurança,
                                                        problemas exclusivamente
                                                        por culpa de terceiros
                                                        ocorram, como ataques
                                                        cibernéticos de hackers,
                                                        ou também em decorrência
                                                        da negligência ou
                                                        imprudência do próprio
                                                        usuário, ou até mesmo
                                                        falha no serviço em
                                                        nuvem contratado, falha
                                                        no provedor de internet
                                                        e outros.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        i. É de conhecimento do
                                                        Proprietário que não nos
                                                        responsabilizamos por
                                                        qualquer danos causados
                                                        por problemas de força
                                                        maior, instabilidade de
                                                        serviços de terceiros
                                                        entre outros.
                                                    </div>
                                                    <br />
                                                </p>
                                                <p class="h4">
                                                    4. A plataforma nPass tem
                                                    como objetivo auxiliar os
                                                    Proprietários com as
                                                    funcionalidades:
                                                </p>
                                                <p>
                                                    <div>
                                                        a. Ferramentas de
                                                        auxilio de Controle de
                                                        Acesso a locais,
                                                        denominado pela
                                                        plataforma como nPass,
                                                        configurando data, hora
                                                        e local do
                                                        acontecimento, limite de
                                                        Passaports pago ou
                                                        grátis, descrição do
                                                        nPass entre outros.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        b. Realizar vendas e
                                                        gestão de acessos a
                                                        determinado local
                                                        através dos Passports,
                                                        junto a plataforma de
                                                        pagamento Stripe.
                                                    </div>
                                                    <b />
                                                    <div>
                                                        <br />
                                                        c. Efetuar Check-in e
                                                        Check-out de usuários
                                                        confirmados em seus
                                                        nPass.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        d. Exibir lista e
                                                        números de Passports
                                                        vendidos de seus nPass,
                                                        dando uma visão de
                                                        popularidade do mesmo.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        e. Exibir lista e
                                                        números totais de
                                                        Reações para seus nPass
                                                    </div>
                                                    <div>
                                                        <br />
                                                        f. Habilitar ou não
                                                        Flirt para Confirmados
                                                        em seus nPass.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        g. A plataforma nPass
                                                        poderá sofrer alterações
                                                        em necessidade de
                                                        correções.
                                                    </div>
                                                    <br />
                                                </p>
                                                <p class="h4">
                                                    5. Como e por onde acessar à
                                                    plataforma nPass?
                                                </p>
                                                <p>
                                                    <div>
                                                        a. O acesso a plataforma
                                                        nPass é via aplicativo
                                                        mobile, disponível para
                                                        download na App Store ou
                                                        Play Store. O login é
                                                        realizado através de
                                                        email e senha inseridas
                                                        pelo usuário ao criar
                                                        sua conta.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        b. A conta é
                                                        intransferível e de
                                                        total responsabilidade
                                                        do usuário, a plataforma
                                                        nPass não responderá por
                                                        uso indevido ou dados
                                                        causados por uso não
                                                        autorizados a conta ou
                                                        por outra questão de
                                                        segurança.
                                                    </div>
                                                    <br />
                                                </p>
                                                <p class="h4">
                                                    6. Cadastro de Novos
                                                    Usuários
                                                </p>
                                                <p>
                                                    <div>
                                                        a. O cadastro de usuário
                                                        na plataforma nPass é
                                                        gratuito.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        b. É necessário que o
                                                        usuário seja maior que
                                                        18 anos.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        c. É do compromisso do
                                                        usuário ser íntegro nas
                                                        informações cadastradas,
                                                        caso a equipe
                                                        identifique que as
                                                        informações não são
                                                        verdadeiras, dará
                                                        direito a deleção da
                                                        conta.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        d. As informações
                                                        disponibilizada na hora
                                                        do cadastro é de total
                                                        responsabilidade do
                                                        Proprietário
                                                    </div>
                                                    <br />
                                                </p>
                                                <p class="h4">
                                                    7. Ao cadastrar na
                                                    plataforma nPass, o
                                                    Proprietário concorda que:
                                                </p>
                                                <p>
                                                    <div>
                                                        a. As suas informações,
                                                        como nome, foto, nick
                                                        name, entre outras,
                                                        estarão visíveis para
                                                        todos os usuários
                                                        cadastrados na
                                                        plataforma nPass, uma
                                                        vez que, somos uma rede
                                                        disponível para auxiliar
                                                        na venda e gestão de
                                                        acesso e promover
                                                        interação social entre
                                                        os usuários. Desta
                                                        forma, quando você se
                                                        mostra interessado ou
                                                        compartilha e confirma
                                                        presença em algum nPass,
                                                        suas ações serão
                                                        exibidas para seus
                                                        seguidores no próprio
                                                        feed. Qualquer usuário
                                                        do aplicativo poderá te
                                                        seguir ou vice versa,
                                                        podendo visualizar suas
                                                        ações dentro da
                                                        plataforma.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        b. É de responsabilidade
                                                        do Proprietário as ações
                                                        efetuadas pela sua
                                                        conta, e todas as
                                                        informações prestadas a
                                                        plataforma nPass. A
                                                        plataforma não se
                                                        responsabiliza pelos
                                                        crimes vinculados a
                                                        informações falsas,
                                                        estelionato, entre
                                                        outros. O Proprietário
                                                        se responsabilizará em
                                                        responder judicialmente
                                                        pelos seus atos.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        c. É de obrigação do
                                                        Proprietário se portar
                                                        de maneira ética perante
                                                        a lei.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        d. Em seu perfil, será
                                                        exibido publicamente,
                                                        junto suas informações
                                                        de cadastro, quatro abas
                                                        de listas de nPass,
                                                        classificadas como:{" "}
                                                        <br /> <br />
                                                        <span> * </span>
                                                        Criado: Onde será
                                                        listado todos nPass
                                                        organizado por você
                                                        dentro da plataforma
                                                        nPass. <br /> <br />{" "}
                                                        <span> * </span>
                                                        Interessado: Onde será
                                                        listado todos nPass
                                                        interessado por você
                                                        dentro da plataforma
                                                        nPass.
                                                        <br /> <br />{" "}
                                                        <span> * </span>Estive:
                                                        Onde será listado todos
                                                        nPass passados que você
                                                        confirmou dentro da
                                                        plataforma nPass. <br />{" "}
                                                        <br /> <span> * </span>
                                                        Confirmado: Onde será
                                                        listado todos nPass
                                                        futuros que você
                                                        confirmou dentro da
                                                        plataforma nPass.
                                                    </div>
                                                    <br />
                                                </p>
                                                <p class="h4">
                                                    8. Limitação de
                                                    responsabilidade
                                                </p>
                                                <p>
                                                    <div>
                                                        a. A plataforma nPass
                                                        não controla ações
                                                        executadas pelos
                                                        Usuários, Consumidores e
                                                        Proprietários. Com isso,
                                                        os Usuários que
                                                        aceitarem os presentes
                                                        termos e ingressarem na
                                                        plataforma,
                                                        automaticamente
                                                        declaram-se cientes que
                                                        a plataforma nPass não
                                                        tem responsabilidade
                                                        judicial por suas
                                                        atitudes de caráter
                                                        ilícitos, crimes, entre
                                                        outros, e responderão
                                                        por essas atitudes.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        b. Os Usuários,
                                                        Consumidores e
                                                        Proprietários
                                                        declaram-se cientes que
                                                        a plataforma nPass não
                                                        tem responsabilidade
                                                        judicial nos danos
                                                        diretos ou indireto
                                                        ocasionados por serviços
                                                        de terceiros, como ações
                                                        de hackers, queda de
                                                        servidor, falhas, vírus
                                                        e outros.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        c. Os Usuários,
                                                        Consumidores e
                                                        Proprietários
                                                        declaram-se cientes que
                                                        a plataforma nPass não
                                                        tem responsabilidade
                                                        judicial em ações
                                                        acontecidas dentro dos
                                                        eventuais nPass
                                                        organizados, como
                                                        vazamentos dos
                                                        conteúdos,
                                                        compartilhamentos,
                                                        fraudes entre outros.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        d. Os Proprietários
                                                        declaram-se cientes que
                                                        a criação, organização,
                                                        gestão, condição de
                                                        venda, cancelamentos e
                                                        monitoramento dos seus
                                                        nPass organizados é de
                                                        sua inteira, total e
                                                        irrestrita
                                                        responsabilidade. A
                                                        plataforma nPass não
                                                        responderá por nenhum
                                                        evento judicial que
                                                        envolva essas
                                                        responsabilidades,
                                                        estamos aqui apenas para
                                                        auxiliar e conectar
                                                        Proprietários e
                                                        Consumidores.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        e. Gestão financeira,
                                                        como reembolsos,
                                                        disputas, repasse, serão
                                                        tratadas diretamente
                                                        pelo Proprietário com a
                                                        intermediadora
                                                        Financeira Stripe.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        f. As ações de Check-in
                                                        ou Check-out, validação
                                                        de fraudes de
                                                        informação, idade,
                                                        contida no Passport são
                                                        de responsabilidade do
                                                        Proprietário ou Admins
                                                        configurados pelo
                                                        próprio Proprietário.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        g. A plataforma nPass
                                                        não se responsabiliza
                                                        pelo Check-in ou
                                                        Check-out efetuado no
                                                        momento da entrada do
                                                        nPass, é de
                                                        responsabilidade do
                                                        Proprietário, validar as
                                                        condições e informações
                                                        contidas no Passport,
                                                        seja ele apresentado
                                                        impresso ou
                                                        digitalmente. Nós não
                                                        interferimos nessas
                                                        ações, apenas lemos o
                                                        QR-Code contido no
                                                        Passport e exibimos as
                                                        informações contidas no
                                                        mesmo caso existentes, a
                                                        ação de Check-in ou
                                                        Check-out é de total
                                                        responsabilidade do
                                                        Proprietário.
                                                    </div>
                                                    <br />
                                                </p>
                                                <p class="h4">
                                                    9. Venda de acesso pelos
                                                    Passports
                                                </p>
                                                <p>
                                                    <div>
                                                        a. A compra dos
                                                        Passports, documento
                                                        digital de acesso ao
                                                        nPass, é feita por um
                                                        usuário válido e
                                                        cadastrado na plataforma
                                                        nPass, onde o
                                                        Consumidor, através da
                                                        tela do nPass desejado,
                                                        poderá adicionar os
                                                        Passports de seu
                                                        interesse que estejam
                                                        disponíveis, e logo,
                                                        prosseguir com
                                                        pagamento, que será
                                                        feito via cartão de
                                                        crédito avista, pelo
                                                        intermediador financeiro
                                                        Stripe.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        b. Os pagamentos serão
                                                        intemediados pela
                                                        plataforma Stripe, que
                                                        disponibiliza ao
                                                        Proprietário gerenciar
                                                        suas ações financeiras
                                                        através do site
                                                        https://dashboard.stripe.com/login
                                                        ou clicando no botão
                                                        “Acessa Carteira”
                                                        contida na tela do nPass
                                                        organizado, você será
                                                        redirecionado para
                                                        página de login do
                                                        Stripe. A plataforma
                                                        nPass não tem ações
                                                        financeiras sobre a
                                                        conta do usuário, apenas
                                                        retém a taxa de serviço.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        c. A plataforma Stripe é
                                                        responsável pelas
                                                        transações financeiras,
                                                        e retém 3,99% + R$ 0,39
                                                        por transação, podendo
                                                        sofrer alterações.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        d. A taxa retida pela
                                                        plataforma nPass é de 6%
                                                        por cada transação
                                                        financeira, o que
                                                        totaliza, 9.99% + R$
                                                        0,39 por cada transação
                                                        junto com a taxa do
                                                        Stripe.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        e. Em uma transação de
                                                        venda de um Passaport, o
                                                        único item que a
                                                        plataforma nPass tem
                                                        ação para gestão, é na
                                                        taxa de serviço de 6%
                                                        por cada transação.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        f. Regras de reembolso
                                                        para o Consumidor é de
                                                        responsabilidade do
                                                        Proprietário e efetuada
                                                        pelo mesmo pela sua
                                                        conta criada na
                                                        plataforma Stripe, o
                                                        nPass não tem acesso a
                                                        essas ações.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        g. As informações
                                                        necessárias para gerar o
                                                        documento digital na
                                                        hora da compra do
                                                        Passport, como nome,
                                                        email e documento, é de
                                                        total responsabilidade
                                                        do Consumidor, pois
                                                        tendo informações
                                                        incorretas, poderá
                                                        impedir o acesso ao
                                                        nPass desejado.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        h. As informações
                                                        inseridas na hora da
                                                        compra, por segurança do
                                                        nPass, não poderão ser
                                                        alteradas, então
                                                        certifique que as
                                                        informações estão
                                                        corretas, antes de
                                                        confirmar o pagamento.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        i. Após a plataforma
                                                        Stripe confirmar o
                                                        pagamento, será gerado
                                                        o(s) Passaport(s) ao
                                                        Consumidor, que será
                                                        exibido na aba de
                                                        Passports da sua conta
                                                        no aplicativo.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        j. O Proprietário só
                                                        poderá realizar um nPass
                                                        com Passaports grátis,
                                                        em casos de entrada
                                                        franca. No entanto, se o
                                                        Proprietário fizer uso
                                                        desse recurso na
                                                        plataforma, e efetuar
                                                        taxa de cobrança no
                                                        local, sua conta poderá
                                                        ser denunciada,
                                                        analisada pela equipe e
                                                        poderá ser deletada.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        k. Após um Passaport ser
                                                        vendido, impossibilitará
                                                        de que o mesmo seja
                                                        editado ou deletado,
                                                        tornando
                                                        responsabilidade do
                                                        Proprietário reembolsar
                                                        o Consumidor.
                                                    </div>
                                                    <br />
                                                </p>
                                                <p class="h4">
                                                    10. Responsabilidades e
                                                    obrigações dos
                                                    Proprietários.
                                                </p>
                                                <p>
                                                    <div>
                                                        a. Publicar nPass ou
                                                        quaisquer conteúdos que
                                                        sejam verdadeiros e com
                                                        informações completas e
                                                        exatas sobre: local,
                                                        data, horário, valores,
                                                        atrações, serviços
                                                        oferecidos, contato,
                                                        política de
                                                        cancelamento, política
                                                        de meia-entrada para
                                                        fins de atendimento à
                                                        Lei nº 12.933/13 e o
                                                        Decreto nº 8.537/2015,
                                                        restrição de faixa
                                                        etária e qualquer outra
                                                        informação que seja
                                                        relevante para os
                                                        Consumidores e
                                                        Participantes, ou ainda,
                                                        para a validação da
                                                        realização dos eventos;
                                                    </div>
                                                    <div>
                                                        <br />
                                                        b. Cumprir com tudo que
                                                        foi anunciado, podendo
                                                        responder criminalmente
                                                        por publicidade
                                                        enganosa.
                                                    </div>
                                                    <br />
                                                    c. Não se passar por outra
                                                    pessoa ou entidade, podendo
                                                    responder criminalmente
                                                    pelos crimes de estelionato,
                                                    falsidade ideológica e
                                                    outros, tendo direitos sobre
                                                    o conteúdo.
                                                    <div>
                                                        <br />
                                                        d. Os Proprietários
                                                        declaram-se cientes que
                                                        a criação, organização,
                                                        gestão, condição de
                                                        venda, cancelamentos e
                                                        monitoramento no seus
                                                        nPass organizados é de
                                                        sua inteira, total,
                                                        irrestrita
                                                        responsabilidade, e a
                                                        plataforma nPass não
                                                        respondera por nenhum
                                                        evento judicial que
                                                        envolva essas
                                                        responsabilidades,
                                                        estamos aqui apenas para
                                                        auxiliar e conectar
                                                        Proprietários e
                                                        consumidores.
                                                    </div>
                                                    <br />
                                                </p>
                                                <p class="h4">
                                                    11. Cancelamento, alterações
                                                    e reembolso de compras.
                                                </p>
                                                <p>
                                                    <div>
                                                        a. A ação e regra de
                                                        reembolso das vendas de
                                                        Passport é de total
                                                        responsabilidade do
                                                        Proprietário, ficando
                                                        ele obrigado a detalhar
                                                        no descritivo do nPass
                                                        todas as informações
                                                        necessárias de politica
                                                        de cancelamento e
                                                        reembolso, tendo
                                                        obrigatoriamente que
                                                        cumprir segundo o código
                                                        do consumidor.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        b. O reembolso não será
                                                        feito pela plataforma
                                                        nPass, e sim pelo
                                                        intermediador financeiro
                                                        Stripe. A plataforma
                                                        nPass apenas direciona o
                                                        pagamento pro mesmo, e
                                                        não tem controle e ação
                                                        sobre essas funções.
                                                    </div>
                                                    <br />
                                                    c. Caso o nPass não ocorra,
                                                    é de responsabilidade e
                                                    obrigatoriedade o
                                                    Proprietário arcar com o
                                                    reembolso de todos os
                                                    Consumidores. Caso o
                                                    Proprietário não cumpra como
                                                    planejado, poderá receber
                                                    uma ação de reclamação ou
                                                    processo judicial.
                                                    <br />
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    12. Denuncia de conteúdo e
                                                    mensagens inadequadas ou
                                                    preocupações de segurança
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. Se tiver alguma
                                                        preocupação sobre uso
                                                        inapropriado ou inseguro
                                                        do nPass, você pode
                                                        denunciar o conteúdo
                                                        diretamente no app. Uma
                                                        diferença de opinião não
                                                        é motivo suficiente para
                                                        denunciar algo. .
                                                    </div>

                                                    <div>
                                                        <br />
                                                        b. Depois de avaliar os
                                                        itens denunciados, nós
                                                        os excluiremos se for
                                                        necessário. Não podemos
                                                        divulgar a identidade da
                                                        pessoa que denunciou um
                                                        item, conforme
                                                        estabelecido na nossa
                                                        Política de Privacidade.
                                                        Temos capacidade
                                                        limitada de agir com
                                                        relação a conteúdo
                                                        exibido fora do nossa
                                                        plataforma, a menos que
                                                        se trate de uma violação
                                                        direta da nossa marca.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        c. Tarefas relacionadas:
                                                    </div>

                                                    <div>
                                                        <br />
                                                        *1. É spam.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        *2. Nudez ou atividade
                                                        sexual.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        *3. Símbolos ou discurso
                                                        de ódio.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        *4. Symbols or hate
                                                        speech.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *5. Informação falsa.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *6. Golpe ou fraude.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *7. Scam or Fraud.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *8. Bullyng ou assédio.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *9. Violação de
                                                        propriedade intelectual.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *10. Suicidio ou
                                                        automutilação. .
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *11. Venda de produtos
                                                        regulamentados ou
                                                        ilícitos.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *12. Distúrbios
                                                        alimentares.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *13. Outra coisa.
                                                    </div>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Card>
                        </Container>
                    </section>
                </main>
                <SimpleFooter />
            </>
        );
    }
}

export default TermOfUseOwner;
