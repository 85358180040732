/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import Index from "views/Index.js";
import Landing from "views/examples/Landing.js";
import Login from "views/examples/Login.js";
import TermOfUseOwner from "views/examples/TermOfUseOwner.js";
import TermOfUseCustomer from "views/examples/TermOfUseCustomer.js";
import PrivacyPolicy from "views/examples/PrivacyPolicy";

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/" exact render={(props) => <Index {...props} />} />
            <Route
                path="/landing-page"
                exact
                render={(props) => <Landing {...props} />}
            />
            <Route
                path="/privacy-policy"
                exact
                render={(props) => <PrivacyPolicy {...props} />}
            />
            <Route
                path="/term-of-use-owner-page"
                exact
                render={(props) => <TermOfUseOwner {...props} />}
            />
            <Route
                path="/term-of-use-customer-page"
                exact
                render={(props) => <TermOfUseCustomer {...props} />}
            />
            <Redirect to="/" />
            <Route path="*" element={<TermOfUseOwner to="/" />} />
        </Switch>
    </BrowserRouter>,
    document.getElementById("root"),
);
