/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class PrivacyPolicy extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4"></div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    {/* <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={require("assets/img/theme/team-4-800x800.jpg")}
                          />
                        </a>
                      </div>
                    </Col> */}
                    <Col
                      className="order-lg-3 text-lg-right align-self-lg-center"
                      lg="4"
                    >
                      <div className="card-profile-actions py-4 mt-lg-0">
                        <Button
                          className="mr-4"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          size="sm"
                        >
                          Proprietários
                        </Button>
                        <Button
                          className="float-right"
                          color="default"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          size="sm"
                        >
                          Consumidores
                        </Button>
                      </div>
                    </Col>
                    <Col className="order-lg-1" lg="4">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">22/11/2021 12:51</span>
                          <span className="description">
                            Última atualização
                          </span>
                        </div>
                        {/* <div>
                          <span className="heading">10</span>
                          <span className="description">Photos</span>
                        </div>
                        <div>
                          <span className="heading">89</span>
                          <span className="description">Comments</span>
                        </div> */}
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-5">
                    <h3>Política de Privacidade</h3>
                  </div>
                  <div className="mt-5 py-5 border-top ">
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <p class="h4">
                          {" "}
                          <br />
                          Sobre
                        </p>
                        <p>
                          Na plataforma nPass, privacidade e segurança são
                          prioridades e nos comprometemos com a transparência do
                          tratamento de dados pessoais dos nossos usuários,
                          Organizadores e Consumidores. Por isso, esta presente
                          Política de Privacidade estabelece como é feita a
                          coleta, uso e transferência de informações de clientes
                          ou outras pessoas que acessam ou usam nosso site. Ao
                          utilizar nossos serviços, você entende que coletaremos
                          e usaremos suas informações pessoais nas formas
                          descritas nesta Política, sob as normas de Proteção de
                          Dados (LGPD, Lei Federal 13.709/2018), das disposições
                          consumeristas da Lei Federal 8078/1990 e as demais
                          normas do ordenamento jurídico brasileiro aplicáveis.
                          A atual versão da Política de Privacidade foi
                          formulada e atualizada pela última vez em: (data da
                          última atualização da Política de Privacidade).
                          Reservamos o direito de modificar essa Política de
                          Privacidade a qualquer tempo, principalmente em função
                          da adequação a eventuais alterações feitas em nosso
                          site ou em âmbito legislativo. Recomendamos que você a
                          revise com frequência. Eventuais alterações entrarão
                          em vigor a partir de sua publicação em nosso site e
                          sempre lhe notificaremos acerca das mudanças
                          ocorridas. Ao utilizar nossos serviços e fornecer seus
                          dados pessoais após tais modificações, você as
                          consente. 
                        </p>
                        <br />
                        <p class="h4">
                          {" "}
                          <br />
                          1. Como coletamos os seus dados?
                        </p>
                        <p>
                          A plataforma nPass, agindo como controladora dados
                          pessoais, necessitará colher dados, sejam eles
                          pessoais ou não, para entregar a melhor experiencia
                          para usuário, seja ele Organizador ou Consumidor. E o
                          presente documento tem como objetivo expor como
                          tratamos os dados pessoais, sempre informados pelo
                          usuário de forma voluntária. Os dados fornecidos pelo
                          titular no momento do cadastro:
                          <br />
                          <div>
                            <br />
                            a. Nome, Nick Name, Foto, Descrição, Gênero, Email,
                            Senha. Grande parte desses dados estarão disponíveis
                            para todos os usuários cadastrados na plataforma,
                            possibilitando que os mesmos visualizarem ações no
                            próprio perfil do usuário, assim como os números e
                            lista de usuários seguidores e usuários seguidos,
                            nPass confirmados, interessados, compartilhados.
                          </div>
                          <div>
                            <br />
                            b. Em casos de dados sensíveis, sempre serão
                            coletados ou compartilhados com consentimento do
                            usuário.
                          </div>
                          <div>
                            <br />
                            c. Por opção do usuário, é possível disponibilizar
                            dados para criação da conta via Facebook, Google ou
                            Apple, como nome completo, idade, foto, email,
                            podendo até disponibilizar outros demais. É de opção
                            do usuário permitir o acesso a essas informações,
                            caso não, será necessário informar os dados
                            manualmente, certificando que os mesmos são íntegros
                            e do próprio usuário.
                          </div>
                          <div>
                            <br />
                            Compartilhamento de interações dentro da plataforma:
                          </div>
                          <div>
                            <br />* a. Ações de confirmação, compartilhamento,
                            interesse nos nPass serão disponíveis para todos os
                            usuários da plataforma através do seu perfil, e
                            seguidores conseguirão visualizar suas reações
                            através do feed, tela principal da plataforma.
                          </div>
                          Outras informações que podemos obter:
                          <div>
                            <br />* a. Dados técnicas do dispositivo, como
                            número de IP, modelo do dispositivo, idioma do
                            dispositivo, sistema operacional, informações de
                            data, operadora e outras.
                          </div>
                          * b. No uso da plataforma nPass, pode ser necessário
                          obtermos dados do usuário sobre contas bancárias,
                          cartões de crédito para objetivo do intermediário
                          financeiro Stripe conclua tais ações necessárias, como
                          compra de Passports, repasses e outros.
                          <div>
                            <br />
                            Lista de dados pessoais fornecidos pelo titular:
                          </div>
                        </p>
                        <br />
                        <p class="h4">2. Como utilizaremos os dados pessoais</p>
                        <p>
                          <div>
                            <br />
                            a. Cadastro para o integração e interação na
                            plataforma nPass, onde obteremos dados como Nome,
                            Nick Nome, Email e Senha, Foto, Descrição, Gênero,
                            Data de Nascimento, Documento sendo que muito dos
                            mesmos serão de acesso a todos os usuário da
                            plataforma que acessarem seu Perfil.
                          </div>
                          <div>
                            <br />
                            b. Flirt, função que tem como objetivo conectar
                            pessoas confirmadas nos mesmos nPass que você. Será
                            exibido sua foto juntamente com o nome dos usuários
                            confirmados. O usuário poderá desabilitar essa
                            função, caso queira.
                          </div>
                          <div>
                            <br />
                            c. Interação dentro da plataforma nas ações de
                            Confirmar, Compartilhar ou Interessar nos nPass
                            disponibilizados. Essa ações, serão vinculada a
                            informações cadastradas e disponível no seu perfil,
                            e serão de acesso aos usuários da plataforma.
                          </div>
                          <div>
                            <br />
                            d. Para o Controle de Acesso, ação disponível aos
                            Organizadores de nPass Confirmados pelo usuário.
                          </div>
                          <div>
                            <br />
                            e. Para emissão de Nota Fiscal, ação disponível aos
                            Organizadores de nPass Confirmados pelo usuário.
                          </div>
                          <div>
                            <br />
                            f. No ato da compras de Passports, para a
                            participação em nPass, dados que poderão ser
                            disponibilizada para o Intermediador Financeiro
                            Stripe.
                          </div>
                          <div>
                            <br />
                            g. Informações gerais, como comunicados do
                            Organizador sobre nPass confirmado, alterações das
                            politicas da plataformas e outros.
                          </div>
                          <div>
                            <br />
                            h. Com objetivo de melhoria da segurança e serviços
                            da plataforma, tal como soluções de problemas
                            técnicos, possíveis fraude, falhas e outros
                            problemas relacionados.
                          </div>
                          <div>
                            <br />
                            i. Com objetivo de publicidade, auditoria de
                            informações, analise de dados, aprimorar produto,
                            coisas internas.
                          </div>
                          <div>
                            <br />
                            j. Para analise de denuncias, que ferem o termo de
                            uso da plataforma nPass:
                          </div>
                          <br />
                          <div>
                            <br />* 1. De cumprimento de obrigação legal, de
                            força maior.
                          </div>
                          <br />
                          <div>
                            * 2. Caso o usuário queira não receber informativos
                            por e-mail, deverá bloquear o e-mail do remetente
                            responsável desejado na sua própria plataforma.
                          </div>
                          <br />
                          <div>
                            * 3. Em caso de necessidade de demais informações
                            ausentes no presente documento, o usuário será
                            previamente informado sobre a atualização deste
                            documento, contendo tais informações atualizadas.
                          </div>
                          <br />
                          <br />
                        </p>
                        <p class="h4">
                          3. Com quem seus dados podem ser compartilhados?
                        </p>
                        <p>
                          <br />
                          <div>
                            a. Com usuários ativos na plataforma nPass, por meio
                            de interação ao Confirmar, Compartilhar, Interessar
                            em nPass disponibilizadas na plataforma. As ações
                            vincularão seus dados pessoais cadastrados, e serão
                            de acesso aos usuários da plataforma disponível no
                            seu Perfil.
                          </div>
                          <div>
                            <br />
                            b. Seus seguidores na plataforma.
                          </div>
                          <div>
                            <br />
                            c. Em caso de prestadores de serviço, que podem ter
                            a necessidade de tratar tais dados em nome da
                            plataforma nPass, como DBA, serviço de e-mail, e
                            processadores de pagamento e outros.
                          </div>
                          <div>
                            <br />
                            d. Os usuários poderão usar serviço de terceiros
                            para o cadastramento na plataforma nPass, como
                            Facebook, Google, apple.
                          </div>
                          <div>
                            <br />
                            e. A plataforma nPass, sempre que requisitado,
                            compartilha dados de seus usuários pra autoridades
                            de força maior, como polícias e autoridades
                            competentes ou ate mesmo terceiros.
                          </div>
                          <div>
                            <br />
                            f. Além disso, também existem outras hipóteses em
                            que seus dados poderão ser compartilhados, que são:
                          </div>
                          <div>
                            <br />* 1. Determinação legal, requerimento,
                            requisição ou ordem judicial, com autoridades
                            judiciais, administrativas ou governamentais
                            competentes.
                          </div>
                          <div>
                            <br />* 2. Caso de movimentações societárias, como
                            fusão, aquisição e incorporação, de forma automática
                          </div>
                          <div>
                            <br />* 3. Proteção dos direitos da plataforma
                            nPass em qualquer tipo de conflito, inclusive os de
                            teor judicial.
                          </div>
                        </p>
                        <p class="h4">
                          <br />
                          4. Como e por quanto tempo seus dados serão
                          armazenados?
                        </p>
                        <p>
                          <div>
                            <br />
                            Seus dados pessoais coletados pela plataforma
                            nPass serão utilizados e armazenados em nuvem
                            durante o tempo necessário para a prestação do
                            serviço ou para que as finalidades elencadas na
                            presente Política de Privacidade sejam atingidas,
                            considerando os direitos dos titulares dos dados e
                            dos controladores. De modo geral, seus dados serão
                            mantidos enquanto a relação contratual entre você e
                            a plataforma nPass perdurar. Findado o período de
                            armazenamento dos dados pessoais, estes serão
                            excluídos de nossas bases de dados ou anonimizados,
                            ressalvadas as hipóteses legalmente previstas no
                            artigo 16 lei geral de proteção de dados, a saber:
                          </div>
                          <div>
                            <br />* 1. Cumprimento de obrigação legal ou
                            regulatória pelo controlador;
                          </div>
                          <div>
                            <br />* 2. Estudo por órgão de pesquisa, garantida,
                            sempre que possível, a anonimização dos dados
                            pessoais;
                          </div>
                          <div>
                            <br />* 3. Transferência a terceiro, desde que
                            respeitados os requisitos de tratamento de dados
                            dispostos nesta Lei; ou
                          </div>
                          <div>
                            <br />* 4. Uso exclusivo do controlador, vedado seu
                            acesso por terceiro, e desde que anonimizados os
                            dados. Isto é, informações pessoais sobre você que
                            sejam imprescindíveis para o cumprimento de
                            determinações legais, judiciais e administrativas
                            e/ou para o exercício do direito de defesa em
                            processos judiciais e administrativos serão
                            mantidas, a despeito da exclusão dos demais dados. 
                            O armazenamento de dados coletados pela plataforma
                            nPass reflete o nosso compromisso com a segurança e
                            privacidade dos seus dados. Empregamos medidas e
                            soluções técnicas de proteção aptas a garantir a
                            confidencialidade, integridade e inviolabilidade dos
                            seus dados. Além disso, também contamos com medidas
                            de segurança apropriadas aos riscos e com controle
                            de acesso às informações armazenadas.
                          </div>
                        </p>
                        <p class="h4">
                          <br />
                          5. O que fazemos para manter seus dados seguros?
                        </p>
                        <p>
                          <div>
                            Para mantermos suas informações pessoais seguras,
                            usamos ferramentas físicas, eletrônicas e gerenciais
                            orientadas para a proteção da sua privacidade.
                            Aplicamos essas ferramentas levando em consideração
                            a natureza dos dados pessoais coletados, o contexto
                            e a finalidade do tratamento e os riscos que
                            eventuais violações gerariam para os direitos e
                            liberdades do titular dos dados coletados e
                            tratados. Entre as medidas que adotamos, destacamos
                            as seguintes:
                          </div>

                          <div>
                            <br />
                            a. Apenas pessoas autorizadas têm acesso a seus
                            dados pessoais
                          </div>
                          <div>
                            <br />
                            b. O acesso a seus dados pessoais é feito somente
                            após o compromisso de confidencialidade
                          </div>
                          <div>
                            <br />
                            c. Seus dados pessoais são armazenados em ambiente
                            seguro e idôneo em nuvem, podendo ser localizado no
                            Brasil ou fora do ambiente nacional.
                          </div>
                          <div>
                            <br />
                            d. A plataforma nPass se compromete a adotar as
                            melhores posturas para evitar incidentes de
                            segurança. Contudo, é necessário destacar que
                            nenhuma tecnologia é inteiramente segura e livre de
                            riscos. É possível que, apesar de todos os nossos
                            protocolos de segurança, problemas de culpa
                            exclusivamente de terceiros ocorram, como ataques
                            cibernéticos de hackers, ou também em decorrência da
                            negligência ou imprudência do próprio usuário.
                          </div>
                          <div>
                            <br />
                            e. Em caso de incidentes de segurança que possa
                            gerar risco ou dano relevante para você ou qualquer
                            um de nossos usuários/clientes, comunicaremos aos
                            afetados e a Autoridade Nacional de Proteção de
                            Dados sobre o ocorrido, em consonância com as
                            disposições da Lei Geral de Proteção de Dados.
                            <br />
                          </div>
                        </p>
                        <p class="h4">
                          <br />
                          6. Trasferencias internacional de dados pessoais
                        </p>
                        <p>
                          <div>
                            <br />
                            Alguns dos terceiros com quem compartilhamos seus
                            dados podem ser localizados ou ou possuir
                            instalações localizadas em países estrangeiros.
                            Nessas condições, de toda forma, seus dados pessoais
                            estarão sujeitos à Lei Geral de Proteção de Dados e
                            às demais legislações brasileiras de proteção de
                            dados. Nesse sentido, a plataforma nPass se
                            compromete a sempre adotar eficientes padrões de
                            segurança cibernética e de proteção de dados, nos
                            melhores esforços de garantir e cumprir as
                            exigências legislativas. Ao concordar com essa
                            Política de Privacidade, você concorda com esse
                            compartilhamento, que se dará conforme as
                            finalidades descritas no presente instrumento.
                          </div>
                          <br />
                        </p>
                        <p class="h4">7. Quais são seus direitos?</p>
                        <p>
                          <div>
                            <br />
                            A plataforma nPass assegura a seus usuários seus
                            direitos de titular previstos no artigo 18 da Lei
                            Geral de Proteção de Dados. Dessa forma, por meio do
                            email support@npass.com.br você pode, de maneira
                            gratuita e a qualquer tempo:
                          </div>
                          <div>
                            <br />
                            a. Confirmar a existência de tratamento de dados, de
                            maneira simplificada ou em formato claro e completo.
                          </div>
                          <div>
                            <br />
                            b. Poderá contatar Organizador através do email
                            disponibilizado pelo mesmo, informação incluída na
                            nPass organizado.
                          </div>
                          <div>
                            <br />
                            c. Acessar seus dados, podendo solicitá-los em uma
                            cópia legível sob forma impressa ou por meio
                            eletrônico, seguro e idôneo.
                          </div>
                          <div>
                            <br />
                            d. Corrigir seus dados em diversas situações,
                            podendo solicitar a edição, correção ou atualização
                            destes.
                          </div>
                          <div>
                            <br />
                            e. Limitar seus dados quando desnecessários,
                            excessivos ou tratados em desconformidade com a
                            legislação através da anonimização, bloqueio ou
                            eliminação.
                          </div>
                          <div>
                            <br />
                            f. Solicitar a portabilidade de seus dados, através
                            de um relatório de dados cadastrais que a plataforma
                            nPass trata a seu respeito.
                          </div>
                          <div>
                            <br />
                            g. Eliminar seus dados tratados a partir de seu
                            consentimento, exceto nos casos previstos em lei.
                            Sendo que os demais dados podem estar presente nos
                            demais banco de dados dos organizadores antes de
                            recebermos a tal ação de deleção.
                          </div>
                          <div>
                            <br />
                            h. Revogar seu consentimento, desautorizando o
                            tratamento de seus dados.
                          </div>
                          <div>
                            <br />
                            i. Informar-se sobre a possibilidade de não fornecer
                            seu consentimento e sobre as consequências da
                            negativa.
                          </div>
                          <div>
                            <br />
                            j. De forma a garantir a sua correta identificação
                            como titular dos dados pessoais objeto da
                            solicitação, é possível que solicitemos documentos
                            ou demais comprovações que possam comprovar sua
                            identidade. Nessa hipótese, você será informado
                            previamente.
                          </div>
                        </p>
                        <p class="h4">8. Responsabilidade</p>
                        <p>
                          <div>
                            <br />
                            A plataforma nPass prevê a responsabilidade dos
                            agentes que atuam nos processos de tratamento de
                            dados, em conformidade com os artigos 42 ao 45 da
                            Lei Geral de Proteção de Dados. Nos comprometemos em
                            manter esta Política de Privacidade atualizada,
                            observando suas disposições e zelando por seu
                            cumprimento. Além disso, também assumimos o
                            compromisso de buscar condições técnicas e
                            organizativas seguramente aptas a proteger todo o
                            processo de tratamento de dados. Caso a Autoridade
                            Nacional de Proteção de Dados exija a adoção de
                            providências em relação ao tratamento de dados
                            realizado pela (nome empresarial
                            simplificado), comprometemo-nos a segui-las. 
                          </div>
                          <br />
                        </p>
                        <p class="h4">9. Isenção de responsabilidade</p>
                        <p>
                          <div>
                            <br />
                            Conforme mencionado no Tópico 5, embora adotemos
                            elevados padrões de segurança a fim de evitar
                            incidentes, não há nenhuma página virtual
                            inteiramente livre de riscos. Nesse sentido,
                            a plataforma nPass não se responsabiliza por:
                          </div>
                          <div>
                            <br />
                            a. Quaisquer consequências decorrentes da
                            negligência, imprudência ou imperícia dos usuários
                            em relação a seus dados individuais. Garantimos e
                            nos responsabilizamos apenas pela segurança dos
                            processos de tratamento de dados e do cumprimento
                            das finalidades descritas no presente instrumento.
                            Destacamos que a responsabilidade em relação à
                            confidencialidade dos dados de acesso é do usuário.
                          </div>
                          <div>
                            <br />
                            b. Ações maliciosas de terceiros, como ataques
                            de hackers, exceto se comprovada conduta culposa ou
                            deliberada da plataforma nPass. Destacamos que em
                            caso de incidentes de segurança que possam gerar
                            risco ou dano relevante para você ou qualquer um de
                            nossos usuários, comunicaremos aos afetados e a
                            Autoridade Nacional de Proteção de Dados sobre o
                            ocorrido.
                          </div>
                          <div>
                            <br />
                            c. Inveracidade das informações inseridas pelo
                            usuário nos registros necessários para a utilização
                            dos serviços da plataforma nPass; quaisquer
                            consequências decorrentes de informações falsas ou
                            inseridas de má-fé são de inteiramente
                            responsabilidade do usuário.
                          </div>
                        </p>
                        <p class="h4">
                          <br />
                          10. Consentimento
                        </p>
                        <p>
                          <div>
                            <br />É a partir do seu consentimento que tratamos
                            os seus dados pessoais. O consentimento é a
                            manifestação livre, informada e inequívoca pela qual
                            você autoriza a plataforma nPass a tratar seus
                            dados. Em consonância com a Lei Geral de Proteção de
                            Dados, seus dados só serão coletados, tratados e
                            armazenados mediante prévio e expresso
                            consentimento.  O seu consentimento será obtido de
                            forma específica para cada finalidade acima
                            descrita, evidenciando o compromisso de
                            transparência e boa-fé da plataforma nPass para com
                            seus usuários, Organizadores ou Consumidores,
                            seguindo as regulações legislativas pertinentes. Ao
                            utilizar os serviços da plataforma nPass e fornecer
                            seus dados pessoais, você está ciente e consentindo
                            com as disposições desta Política de Privacidade,
                            além de conhecer seus direitos e como exercê-los. A
                            qualquer tempo e sem nenhum custo, você poderá
                            revogar seu consentimento. É importante destacar que
                            a revogação do consentimento para o tratamento dos
                            dados pode implicar a impossibilidade da performance
                            adequada de alguma funcionalidade da plataforma que
                            dependa da operação. Tais consequências serão
                            informadas previamente.
                          </div>
                          <br />
                          <p class="h4">9. Disposições Gerais</p>
                          <div>
                            <br />
                            10.1. Ao navegar pela plataforma nPass e utilizar
                            suas funcionalidades, os usuários aceitam todo o
                            disposto nesta Política de Privacidade e demais
                            políticas que se encontrem vigentes na data de
                            acesso, tais como a Política de Cookies e os Termos
                            de Uso. Por isso, é recomendável que os usuários se
                            mantenham atualizados. 
                          </div>
                          <div>
                            <br />
                            10.2. A presente Política de Privacidade está
                            sujeita a constante melhoria e aprimoramento. Assim,
                            a nPass se reserva o direito de modificá-la a
                            qualquer momento, conforme a finalidade da
                            plataforma nPass, tal qual para adequação e
                            conformidade legal de disposição de lei ou norma que
                            tenha força jurídica equivalente, cabendo aos
                            usuários verificá-la sempre que efetuar o acesso à
                            plataforma nPass. Quando houver alteração relevante
                            na Política de Privacidade, a nPass comunicará os
                            usuários adequadamente.
                          </div>
                          <div>
                            <br />
                            10.3. A eventual tolerância quanto a qualquer
                            violação dos termos e condições do contido neste
                            domínio será considerada mera liberalidade e não
                            será interpretada como novação, precedente
                            invocável, renúncia a direitos, alteração tácita dos
                            termos contratuais, direito adquirido ou alteração
                            contratual.
                          </div>
                          <div>
                            <br />
                            10.4. Caso alguma disposição desta Política for
                            julgada inaplicável ou sem efeito, o restante das
                            normas continuam a viger, sem a necessidade de
                            medida judicial que declare tal assertiva. Os termos
                            aqui descritos serão interpretados segundo a
                            legislação brasileira.
                          </div>
                          <div>
                            <br />
                            10.5. A comunicação entre a nPass e o usuário
                            deverá ser realizada pelos canais de atendimento
                            indicados e disponibilizados na plataforma nPass,
                            sobretudo através do e-mail do
                            Suporte suporte@nPass.com.br, do formulário
                            web para exercício de direitos ou do e-mail do
                            Encarregado pelo Tratamento de Dados
                            Pessoais privacidade@nPass.com.br.
                          </div>
                          <div>
                            <br />
                            10.6. Sempre que possível, potenciais divergências
                            entre o usuário e a nPass serão resolvidas de forma
                            amigável. Quando todos os esforços neste sentido
                            forem esgotados, você concorda, desde já, que fica
                            eleito o foro da comarca de Belo Horizonte, no
                            Estado de Minas Gerais, para resolver controvérsias
                            ou queixas oriundas da utilização de nossa
                            plataforma ou relacionadas a esta Política de
                            Privacidade
                          </div>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default PrivacyPolicy;
