/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class TermOfUseCustomer extends React.Component {
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <>
                <DemoNavbar />
                <main className="profile-page" ref="main">
                    <section className="section-profile-cover section-shaped my-0">
                        {/* Circles background */}
                        <div className="shape shape-style-1 shape-default alpha-4"></div>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </section>
                    <section className="section">
                        <Container>
                            <Card className="card-profile shadow mt--300">
                                <div className="px-4">
                                    <Row className="justify-content-center">
                                        {/* <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={require("assets/img/theme/team-4-800x800.jpg")}
                          />
                        </a>
                      </div>
                    </Col> */}
                                        <Col
                                            className="order-lg-3 text-lg-right align-self-lg-center"
                                            lg="4"
                                        >
                                            <div className="card-profile-actions py-4 mt-lg-0">
                                                <Button
                                                    className="mr-4"
                                                    color="info"
                                                    href="#pablo"
                                                    onClick={(e) =>
                                                        e.preventDefault()
                                                    }
                                                    size="sm"
                                                >
                                                    Consumidores
                                                </Button>
                                                <Button
                                                    className="float-right"
                                                    color="default"
                                                    href="#pablo"
                                                    onClick={(e) =>
                                                        e.preventDefault()
                                                    }
                                                    size="sm"
                                                >
                                                    Usuários
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col className="order-lg-1" lg="4">
                                            <div className="card-profile-stats d-flex justify-content-center">
                                                <div>
                                                    <span className="heading">
                                                        21/01/2022 12:51
                                                    </span>
                                                    <span className="description">
                                                        Última atualização
                                                    </span>
                                                </div>
                                                {/* <div>
                          <span className="heading">10</span>
                          <span className="description">Photos</span>
                        </div>
                        <div>
                          <span className="heading">89</span>
                          <span className="description">Comments</span>
                        </div> */}
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="text-center mt-5">
                                        <h3>Termos de Uso - Consumidor</h3>
                                        {/* <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Proprietário
                    </div> */}
                                        {/* <div className="h6 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Proprietário
                    </div> */}
                                        {/* <div>
                      <i className="ni education_hat mr-2" />
                      University of Computer Science
                    </div> */}
                                    </div>
                                    <div className="mt-5 py-5 border-top ">
                                        <Row className="justify-content-center">
                                            <Col lg="9">
                                                <p class="h4">
                                                    1. Aceitação do Termo De Uso
                                                    e Política De Privacidade:
                                                </p>
                                                <p>
                                                    Ao acessar e utilizar os
                                                    serviços disponibilizados
                                                    pela plataforma nPass, o
                                                    usuário confirma que leu,
                                                    compreendeu e concordou com
                                                    o Termo de Uso e a Política
                                                    de Privacidade aplicáveis à
                                                    ferramenta.
                                                </p>
                                                <br />
                                                <p class="h4">2. Glossário:</p>
                                                <p>
                                                    <div>
                                                        Usuário: Termo utilizado
                                                        para denominar
                                                        Consumidores ou
                                                        Proprietários com
                                                        cadastro ativo que
                                                        utilizam a plataforma
                                                        nPass.
                                                    </div>
                                                    <br />
                                                    <div>
                                                        Proprietário: Termo
                                                        utilizado para denominar
                                                        pessoas (físicas ou
                                                        jurídicas) que tem como
                                                        objetivo criar e
                                                        gerenciar acessos,
                                                        através da Plataforma
                                                        nPass e divulgar a
                                                        Consumidores.
                                                    </div>
                                                    <br />
                                                    <div>
                                                        Consumidor: Termo
                                                        utilizado para denominar
                                                        pessoas que adquirem
                                                        Passports garantindo
                                                        acesso em determinado
                                                        nPass criado.
                                                    </div>
                                                    <br />
                                                    <div>
                                                        Passports: Termo
                                                        utilizado para denominar
                                                        o documento digital,
                                                        adquirido de forma paga
                                                        ou gratuita, contendo
                                                        informações necessárias
                                                        que permite o acesso a
                                                        determinado nPass
                                                        criado.
                                                    </div>
                                                    <br />
                                                    <div>
                                                        Passports Host: Termo
                                                        utilizado para denominar
                                                        o documento digital,
                                                        adquirido de forma paga
                                                        ou gratuita, contendo
                                                        informações necessárias
                                                        que permite o acesso a
                                                        determinado nPass
                                                        criado, para não
                                                        proprietário da conta.
                                                    </div>{" "}
                                                    <br />
                                                    <div>
                                                        Plataforma nPass: É uma
                                                        ferramenta online mobile
                                                        que tem como objetivo
                                                        auxiliar Proprietários,
                                                        na gestão de acessos,
                                                        com objetivo de auxiliar
                                                        no controle das vendas
                                                        para os nPass, através
                                                        de Passports.
                                                    </div>
                                                    <br />
                                                    <div>
                                                        nPass: O termo refere-se
                                                        aos meios que necessitam
                                                        de Controle de Acesso
                                                        criado pelos
                                                        Proprietários.
                                                    </div>{" "}
                                                    <br />
                                                    <div>
                                                        Administrador: Termo
                                                        utilizado para denominar
                                                        pessoas (físicas ou
                                                        jurídicas) que recebeu
                                                        acesso pelo Proprietário
                                                        para ação de Check-in e
                                                        Check-out em determinado
                                                        nPass.
                                                    </div>{" "}
                                                    <br />
                                                    <div>
                                                        Confirmado: Pessoal que
                                                        adquiriu o acesso a
                                                        determinado nPass
                                                        através do Passport,
                                                        sendo ele pago ou não.
                                                    </div>{" "}
                                                    <br />
                                                    <div>
                                                        Check-in: Ato na
                                                        plataforma de ler, via
                                                        camera, o Passport
                                                        contendo QR-Code único
                                                        de acesso do Confirmado,
                                                        dando opção, ao
                                                        Proprietário ou
                                                        Administrador do nPass,
                                                        inserir o Consumidor na
                                                        lista de internos do
                                                        nPass.
                                                    </div>{" "}
                                                    <br />
                                                    <div>
                                                        Check-out: Ato na
                                                        plataforma do
                                                        Proprietário ou
                                                        Administrador, em
                                                        colocar o Consumidor na
                                                        lista de externo em
                                                        determinado nPass.
                                                    </div>
                                                    <div>
                                                        Controle de Acessos: É o
                                                        controle que o
                                                        proprietário ou
                                                        administrador tem de
                                                        quem entrou ou ainda vai
                                                        entrar no local de
                                                        realização do nPass
                                                        através do acesso a
                                                        lista de Consumidores.{" "}
                                                    </div>{" "}
                                                    <br />
                                                    <div>
                                                        Flirt: É um termo
                                                        referente a uma
                                                        funcionalidade dentro da
                                                        plataforma nPass que tem
                                                        por objetivo conectar os
                                                        usuários confirmados no
                                                        nPass e promover
                                                        comunicação entre eles.{" "}
                                                    </div>
                                                    <div>
                                                        Reações: É um termo
                                                        utilizado para se
                                                        referir as ações e
                                                        reações dos usuários aos
                                                        nPass criados. Por
                                                        exemplo, mostrar
                                                        Interesse, compartilhar
                                                        ou confirmar presença no
                                                        nPass publicado.
                                                    </div>{" "}
                                                    <br />
                                                    <div>
                                                        Plataforma Stripe:
                                                        Plataforma de
                                                        pagamentos, intermediário
                                                        financeiro, utilizada
                                                        pela plataforma nPass
                                                        para gerenciar
                                                        pagamento, de uso e
                                                        acesso exclusivamente do
                                                        Proprietário.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    {" "}
                                                    3. Quem nós somos:
                                                </p>
                                                <p>
                                                    A plataforma nPass é um
                                                    serviço mobile, disponível
                                                    nas lojas Play Store e App
                                                    Store, criada para:
                                                    <div>
                                                        <br />
                                                        a. AUXILIAR na gestão
                                                        dos Proprietários no
                                                        Controle de Acessos
                                                        através de Passports,
                                                        grátis ou pago.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        b. AUXILIAR nas
                                                        operações de vendas
                                                        redirecionando para o
                                                        Intermediador financeiro
                                                        Stripe.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        c. Conectar os
                                                        Proprietários aos
                                                        Consumidores através de
                                                        ferramentas de rede de
                                                        interação.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        d. Promover interação
                                                        social entre
                                                        consumidores confirmados
                                                        no mesmo nPass.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    {" "}
                                                    4. Para o conhecimento do
                                                    Consumidor:
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. Não somos
                                                        responsáveis pelos nPass
                                                        criados, a
                                                        responsabilidade do
                                                        acontecimento e
                                                        organização em geral do
                                                        nPass é total do
                                                        Proprietário que o
                                                        criou. A plataforma
                                                        nPass tem como objetivo
                                                        auxiliar o Proprietário
                                                        nesse processo.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        b. A disponibilização de
                                                        Passports pagos para o
                                                        acesso ao nPass poderão
                                                        ser limitados pelo
                                                        Proprietário e poderá
                                                        ser adquirido pelo
                                                        Consumidor por cartão de
                                                        credito avista através
                                                        do intermediador
                                                        financeiro Stripe.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        c. Os pagamento serão
                                                        intermediadas pela
                                                        plataforma Stripe, que
                                                        disponibiliza ao
                                                        Proprietário gerenciar
                                                        suas ações financeiras,
                                                        como por exemplo,
                                                        reembolso, estornos,
                                                        repasses e outros.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        d. Em uma transação de
                                                        venda de um Passaport, o
                                                        único item que a
                                                        plataforma nPass tem
                                                        ação é na taxa de
                                                        serviço de 6% que é
                                                        cobrada pelo nPass, a
                                                        venda / reembolso /
                                                        disputa e outras ações é
                                                        gerenciada apenas pelo
                                                        Proprietário do mesmo.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        e. A plataforma nPass
                                                        não é uma plataforma de
                                                        pagamento, é uma rede de
                                                        interação, com objetivo
                                                        de gerar Acessos aos
                                                        nPass para os usuários.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        f. O nPass pode sofrer
                                                        alterações até 7 dias
                                                        antes. Por tanto, fique
                                                        atento as informações do
                                                        Proprietário e verifique
                                                        se seu passaporte está
                                                        atualizado.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    5. A plataforma nPass
                                                    auxilia os Proprietários da
                                                    seguinte forma:
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. Criar nPass,
                                                        configurando data e hora
                                                        do acontecimento, local,
                                                        limite de Passaports
                                                        pago ou grátis,
                                                        descrição do nPass e
                                                        outras informações.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        b. Realizar vendas de
                                                        acessos, através dos
                                                        Passports, junto a
                                                        plataforma de pagamento
                                                        Stripe.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        c. Efetuar Check-in e
                                                        Check-out de usuários
                                                        Confirmados em seus
                                                        nPass.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        d. Exibir lista e
                                                        números de Passports
                                                        vendidos de seus nPass,
                                                        dando uma visão de
                                                        popularidade do mesmo.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        e. Exibir lista e
                                                        números totais de
                                                        Reações dos usuários
                                                        para seus nPass.
                                                    </div>

                                                    <div>
                                                        f. Habilitar ou não
                                                        Flirt para Confirmados
                                                        em seus nPass.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        g. Adicionar
                                                        administradores para
                                                        função de Check-in e
                                                        Check-out em seus nPass.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    6. Não somos responsáveis
                                                    pela organização dos nPass,
                                                    apenas conectamos, por meio
                                                    da plataforma, o Consumidor
                                                    com o Proprietário, com
                                                    isso, esteja ciente que:
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. Não temos acesso as
                                                        ações financeiras da
                                                        venda de Passaport do
                                                        nPass organizado, temos
                                                        controle apenas sobre a
                                                        NOSSA taxa de
                                                        conveniência que retemos
                                                        da transação, no valor
                                                        6%.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        b. Proprietário terá
                                                        acesso através da
                                                        plataforma Stripe ( link
                                                        de redirecionamento no
                                                        menu de gerencia do seu
                                                        nPass) a ação de
                                                        reembolsar Consumidores,
                                                        estornar compras,
                                                        disputas e tudo que
                                                        contextualiza
                                                        financeiro, caso
                                                        solicitado pelos
                                                        Consumidores.{" "}
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    {" "}
                                                    7. Compra e acesso aos
                                                    Passports:
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. A compra dos
                                                        Passports, documento
                                                        digital de acesso ao
                                                        nPass, é feita por um
                                                        usuário válido e
                                                        cadastrado na plataforma
                                                        nPass, onde o
                                                        Consumidor, através da
                                                        tela do nPass desejado,
                                                        poderá adicionar os
                                                        Passports de seu
                                                        interesse que estejam
                                                        disponíveis, e logo,
                                                        prosseguir com
                                                        pagamento, que será
                                                        feito via cartão de
                                                        crédito avista, pelo
                                                        intermediador financeiro
                                                        Stripe.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        b. As informações
                                                        necessárias para gerar o
                                                        documento digital na
                                                        hora da compra do
                                                        Passport, como nome,
                                                        email e documento, é de
                                                        total responsabilidade
                                                        do Consumidor, pois
                                                        tendo informações
                                                        incorretas, poderá
                                                        impedir o acesso ao
                                                        nPass desejado;
                                                    </div>

                                                    <div>
                                                        <br />
                                                        c. As informações
                                                        inseridas na hora da
                                                        compra, por segurança do
                                                        nPass, não poderão ser
                                                        alteradas, então
                                                        certifique que as
                                                        informações estão
                                                        corretas, antes de
                                                        confirmar o pagamento.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        d. Após a plataforma
                                                        Stripe confirmar o
                                                        pagamento, será gerado
                                                        o(s) Passaport(s) ao
                                                        Consumidor, que será
                                                        exibido na aba de
                                                        Passports da sua conta
                                                        no aplicativo.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        e. O Proprietário só
                                                        poderá realizar um nPass
                                                        com Passaports grátis,
                                                        em casos de entrada
                                                        franca. No entanto, se o
                                                        Proprietário fizer uso
                                                        desse recurso na
                                                        plataforma, e efetuar
                                                        taxa de cobrança no
                                                        local, no ato da
                                                        entrada, sua conta
                                                        poderá ser denunciada,
                                                        analisada pela equipe e
                                                        poderá ser deletada.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        f. Ao efetuar o
                                                        pagamento, o
                                                        intermediador financeiro
                                                        Stripe e a Plataforma
                                                        nPass recolherão uma
                                                        taxa referente aos
                                                        serviços prestados e o
                                                        restante será repassado
                                                        ao proprietário.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    8. Responsabilidade nos
                                                    cancelamento, reembolso,
                                                    contestação e estornos de
                                                    transações financeiras:
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. As políticas e ação
                                                        de cancelamento e
                                                        Reembolso de transações
                                                        do Consumidor é de total
                                                        responsabilidade do
                                                        Proprietário, a
                                                        plataforma nPass não tem
                                                        acesso a essas ações. Em
                                                        casos em que o
                                                        Consumidor queira
                                                        efetuar o reembolso,
                                                        cancelamentos, estornos,
                                                        e tudo referente ao
                                                        financeiro do nPass, ele
                                                        deverá ter ciência das
                                                        politicas descritas pelo
                                                        Proprietário no nPass, e
                                                        deverá entrar em contato
                                                        com o mesmo através do
                                                        e-mail do Proprietário
                                                        para efetuar essas
                                                        ações.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    9. Taxa de serviço da
                                                    plataforma nPass juntamente
                                                    com intermediador financeiro
                                                    e responsabilidade de
                                                    emissão de nota fiscal
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. A plataforma Stripe é
                                                        responsável pelas
                                                        transações financeiras,
                                                        e retém 3,99% + R$ 0,39
                                                        por transação, podendo
                                                        sofrer alterações.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        b. A taxa retida pela
                                                        plataforma nPass é de 6%
                                                        por cada transação
                                                        financeira, o que
                                                        totaliza, 9.99% + R$
                                                        0,39 por cada transação
                                                        junto com a taxa do
                                                        Stripe.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        c. Caso o Consumidor
                                                        necessite da nota
                                                        fiscal, ele precisará
                                                        entrar em contato direto
                                                        com o Proprietário, pois
                                                        a plataforma nPass não
                                                        tem responsabilidade nem
                                                        acesso a essas
                                                        informações.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    10. Cadastro de Novos
                                                    Usuários
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. O cadastro de usuário
                                                        na plataforma nPass é
                                                        gratuito, o custo
                                                        existirá apenas se o
                                                        usuário quiser adquirir
                                                        acesso aos nPass
                                                        organizados com Passport
                                                        pagos.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        b. É necessário que o
                                                        usuário seja maior que
                                                        18 anos.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        c. Qualquer usuário pode
                                                        criar um nPass e se
                                                        toranar Proprietário e
                                                        responsável pelo mesmo.
                                                        Isto não impossibilita a
                                                        mesma conta ser
                                                        consumidora de outros
                                                        nPass.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        d. É de total
                                                        responsabilidade do
                                                        usuário ser integro nas
                                                        informações cadastradas,
                                                        caso a equipe
                                                        identifique que as
                                                        informações não são
                                                        verdadeiras, dará
                                                        direito a deleção da
                                                        conta, ou ação judicial
                                                        que abrange essas
                                                        questões.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    11. Ao cadastrar na
                                                    plataforma nPass, o usuário
                                                    concorda que:
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. A suas informações,
                                                        como nome, foto, nick
                                                        name, entre outras,
                                                        estarão visíveis para
                                                        todos os usuários
                                                        cadastrados na
                                                        plataforma nPass, uma
                                                        vez que, somos uma rede
                                                        de interação disponível
                                                        para auxiliar na venda e
                                                        criação de nPass e
                                                        promover interação
                                                        social entre os
                                                        usuários. Desta forma,
                                                        quando você se mostra
                                                        interessado ou
                                                        compartilha e confirma
                                                        presença em algum nPass,
                                                        suas ações serão
                                                        exibidas para seus
                                                        seguidores no próprio
                                                        feed;
                                                    </div>

                                                    <div>
                                                        <br />
                                                        b. Qualquer usuário do
                                                        aplicativo poderá te
                                                        seguir ou vice versa,
                                                        podendo visualizar suas
                                                        ações dentro da
                                                        plataforma;
                                                    </div>

                                                    <div>
                                                        <br />
                                                        c. É de responsabilidade
                                                        do Usuário as ações
                                                        efetuadas pela sua
                                                        conta, e todas as
                                                        informações prestadas a
                                                        plataforma nPass,
                                                        concordando o mesmo
                                                        responder judicialmente
                                                        caso as informações
                                                        sejam falsas;
                                                    </div>

                                                    <div>
                                                        <br />
                                                        d. Em seu perfil, será
                                                        exibido publicamente,
                                                        junto suas informações
                                                        de cadastro, quatro abas
                                                        de listas de nPass,
                                                        classificadas como:
                                                    </div>

                                                    <div>
                                                        <br />
                                                        *1. Criado: Onde será
                                                        listado todos nPass
                                                        criado por você dentro
                                                        da plataforma nPass.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        *2. Interessado: Onde
                                                        será listado todos nPass
                                                        interessado por você
                                                        dentro da plataforma
                                                        nPass.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        *3. Estive: Onde será
                                                        listado todos nPass
                                                        passados que você
                                                        confirmou dentro da
                                                        plataforma nPass.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        *4. Confirmado: Onde
                                                        será listado todos nPass
                                                        futuros que você
                                                        confirmou dentro da
                                                        plataforma nPass.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    12. Como obter o acesso à
                                                    plataforma nPass:
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. O acesso a plataforma
                                                        nPass é via aplicativo
                                                        mobile, disponível para
                                                        download na App Store ou
                                                        Play Store. O login é
                                                        realizado através de
                                                        email e senha inseridas
                                                        pelo usuário ao criar
                                                        sua conta. A conta é
                                                        intransferível e de
                                                        total responsabilidade
                                                        do usuário.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    {" "}
                                                    13. Propriedade Intelectual:
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. Em casos no qual um
                                                        usuário realize
                                                        atividades supeitas que
                                                        seja ilegal, prejudicial
                                                        ou infrinja os direitos
                                                        de propriedade
                                                        intelectual de outros, a
                                                        plataforma nPass poderá
                                                        compartilhar suas
                                                        informações a terceiros.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    {" "}
                                                    14. Segurança da plataforma
                                                    nPass:
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. Nenhum usuário tem
                                                        acesso aos servidores,
                                                        back-end, front-end ou
                                                        aos Banco de Dados da
                                                        plataforma nPass. Caso
                                                        haja invasão de hackers,
                                                        a plataforma poderá
                                                        abrir uma ação judicial.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    {" "}
                                                    15. Uso do Flirt
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. Flirt é um termo
                                                        referente a uma
                                                        funcionalidade dentro da
                                                        plataforma nPass que tem
                                                        por objetivo conectar os
                                                        Usuários Confirmados nos
                                                        nPass organizados,
                                                        listando foto e nome,
                                                        promovendo a comunicação
                                                        entre eles, caso
                                                        desejado.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        b. A plataforma nPass
                                                        permite que tanto o
                                                        Usuário Confirmado, como
                                                        o Proprietário do nPass
                                                        desative essa função.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    16. Permissão de acessos a
                                                    nPass via Passports:{" "}
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. Os Passports são
                                                        documentos digitais
                                                        fornecidos pelos
                                                        Proprietários, podendo
                                                        ser adquirido de forma
                                                        paga ou gratuita, que
                                                        permite o acesso aos
                                                        nPass organizados.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        b. Qualquer usuáio da
                                                        plataforma pode
                                                        solicitar a adesão de um
                                                        ou mais Passports Hosts,
                                                        que permite acesso de
                                                        amigos e familiares aos
                                                        nPass através do seu
                                                        login. Não é possivel o
                                                        proprietário da conta
                                                        fazer uso deste
                                                        passports para si mesmo.
                                                        É de responsabilidade do
                                                        Consumidor, as
                                                        informações inseridas
                                                        para a aquisição dos
                                                        Passports Host.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        c. No ato da compra, é
                                                        de responsabilidade do
                                                        Consumidor informar os
                                                        dados necessário via
                                                        formulário para cada
                                                        Passport adquirido, pois
                                                        os mesmos, por segurança
                                                        do nPass não poderão ser
                                                        modificados.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        d. Os Passports poderam
                                                        ser acessados online via
                                                        aplicativo na plataforma
                                                        nPass ou podem ser
                                                        impressos pelo
                                                        Consumidor, é só clicar
                                                        no botão “Imprimir” na
                                                        tela do aplicativo. O
                                                        acesso ao nPass desejado
                                                        pode ser negado caso
                                                        haja esquecimento.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        e. No dia e local
                                                        marcado do nPass, é de
                                                        responsabilidade do
                                                        Consumidor, estar com
                                                        Passport impresso, ou
                                                        garantir acesso a
                                                        internet para que o
                                                        Passport seja acessível
                                                        ao Proprietário, fazendo
                                                        sucesso da leitura do
                                                        QR-Code contido no
                                                        Passport, certificando
                                                        que o documento é valido
                                                        para o acesso.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    17. Proteção das informações
                                                    registradas na plataforma
                                                    nPass:{" "}
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. A plataforma nPass,
                                                        perante uma ordem
                                                        judicial, solicitação de
                                                        autoridade judicial ou
                                                        outro orgão competente,
                                                        será obrigada a
                                                        disponibilizar o acesso
                                                        aos registros, dados
                                                        pessoais, conversas ou
                                                        qualquer outro dado
                                                        armazenado nesta
                                                        plataforma.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        b. Ciente disto, ao
                                                        concordar com o termo, o
                                                        usuário autoriza essa a
                                                        liberação de seus dados
                                                        caso seja requerido
                                                        dentro das condiçoes
                                                        citadas.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    18. Limitação de
                                                    responsabilidade
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. A plataforma nPass
                                                        não controla ações
                                                        executadas pelos
                                                        Usuários, Consumidores e
                                                        Proprietários. Com isso,
                                                        os Usuários que
                                                        aceitarem os presentes
                                                        termos e ingressarem na
                                                        plataforma,
                                                        automaticamente
                                                        declaram-se cientes que
                                                        a plataforma nPass não
                                                        tem responsabilidade
                                                        judicial por suas
                                                        atitudes de caráter
                                                        ilícitos, e outras,
                                                        através da plataforma, e
                                                        responderá por essas
                                                        atitudes.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        b. Os Usuários,
                                                        Consumidores e
                                                        Proprietários
                                                        declaram-se cientes que
                                                        a plataforma nPass não
                                                        tem responsabilidade
                                                        judicial nos danos
                                                        diretos ou indireto
                                                        ocasionados por serviços
                                                        de terceiros, como ações
                                                        de hackers, queda de
                                                        servidor, falhas, vírus
                                                        e outros.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        c. Os Usuários,
                                                        Consumidores e
                                                        Proprietários
                                                        declaram-se cientes que
                                                        a plataforma nPass não
                                                        tem responsabilidade
                                                        judicial em ações
                                                        acontecidas dentro dos
                                                        eventuais nPass
                                                        organizados, como
                                                        vazamentos dos
                                                        conteúdos,
                                                        compartilhamentos,
                                                        fraudes e outros.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        d. Os Usuários,
                                                        Consumidores e
                                                        Proprietários
                                                        declaram-se cientes que
                                                        a plataforma nPass não
                                                        tem responsabilidade
                                                        judicial em qualquer
                                                        tipo de interação social
                                                        ocorrida dentro da
                                                        plataforma.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        e. A Plataforma nPass
                                                        não tem responsabilidade
                                                        judicial sobre
                                                        indenização por ações
                                                        dos Proprietários dos
                                                        nPass. De forma alguma,
                                                        a plataforma poderá
                                                        responder por idenização
                                                        de danos morais, lucros
                                                        cessantes, ou por
                                                        qualquer dano que o
                                                        usuário e/ou consumidor
                                                        tenha sofrido por
                                                        adquirir um passaport
                                                        e/ou vim a participar do
                                                        nPass de seu interesse.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        f. Os usuários e
                                                        consumidores declaram-se
                                                        cientes que a
                                                        participação em nPass
                                                        organizados é de sua
                                                        inteira, total,
                                                        irrestrita
                                                        responsabilidade.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    19. Responsabilidade e
                                                    obrigação dos Consumidores
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. É de inteira
                                                        responsabilidade do
                                                        Consumidor, apresentar
                                                        um documento com foto no
                                                        momento da entrada do
                                                        nPass organizado. Na
                                                        ausência do mesmo, o
                                                        acesso ao nPass poderá
                                                        ser impossibilitado pelo
                                                        Proprietário.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        b. É de inteira
                                                        responsabilidade do
                                                        Consumidor, manter a
                                                        segurança do Passport
                                                        adquirido, assim como no
                                                        cuidado de acessos de
                                                        terceiros a sua conta ou
                                                        compartilhamento do
                                                        código QR-Code. A falta
                                                        de segurança pode
                                                        impossibilitar a
                                                        validação no momento do
                                                        Check-in. A plataforma
                                                        nPass não se
                                                        responsabiliza caso o
                                                        Consumidor compareça no
                                                        nPass sem o Passport,
                                                        seja ele impresso ou
                                                        digital.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        c. O consumidor deverá
                                                        estar ciente que tanto a
                                                        plataforma nPass como o
                                                        intermediador financeiro
                                                        Stripe tem retenção de
                                                        taxa de serviço que
                                                        totalizam 9.99% + R$
                                                        0,39 por cada transação,
                                                        que mesmo com o
                                                        cancelamento ou estorno
                                                        e outros motivos, esse
                                                        custo é irrefutável.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        d. O Consumidor deverá
                                                        estar ciente que a
                                                        plataforma não é
                                                        responsável pela
                                                        organização dos nPass.
                                                        Logo não somos
                                                        responsáveis por nada
                                                        que ocorra referente ao
                                                        mesmo, como
                                                        cancelamentos,
                                                        irregularidades e
                                                        outros. Nosso propósito
                                                        é conectar os
                                                        Proprietários aos
                                                        Consumidores.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        e. É de inteira
                                                        responsabilidade do
                                                        PROPRIETÁRIO, ao
                                                        organizar um nPass,
                                                        seguir com todos os
                                                        protocolos e leis
                                                        exigidas pelos orgãos
                                                        copetentes.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        f. A plataforma nPass
                                                        não tem responsabilidade
                                                        sobre os eventos
                                                        organizados. Sendo
                                                        assim, cabe ao
                                                        PROPRIETÁRIO tomar todos
                                                        os cuidados exigidos
                                                        mediante a covid19.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        g. A plataforma nPass
                                                        não é responsável pela
                                                        realização, organização,
                                                        documentação dos eventos
                                                        ou qualquer ocorrência
                                                        proveniente da
                                                        participação do usuário,
                                                        consumidor e organizador
                                                        durante o período de
                                                        covid19 ou mesmo fora
                                                        deste período.
                                                    </div>
                                                </p>
                                                <br />
                                                <p class="h4">
                                                    20. Denuncia de conteúdo e
                                                    mensagens inadequadas ou
                                                    preocupações de segurança
                                                </p>
                                                <p>
                                                    <div>
                                                        <br />
                                                        a. Se tiver alguma
                                                        preocupação sobre uso
                                                        inapropriado ou inseguro
                                                        do nPass, você pode
                                                        denunciar o conteúdo
                                                        diretamente no app. Uma
                                                        diferença de opinião não
                                                        é motivo suficiente para
                                                        denunciar algo. .
                                                    </div>

                                                    <div>
                                                        <br />
                                                        b. Depois de avaliar os
                                                        itens denunciados, nós
                                                        os excluiremos se for
                                                        necessário. Não podemos
                                                        divulgar a identidade da
                                                        pessoa que denunciou um
                                                        item, conforme
                                                        estabelecido na nossa
                                                        Política de Privacidade.
                                                        Temos capacidade
                                                        limitada de agir com
                                                        relação a conteúdo
                                                        exibido fora do nossa
                                                        plataforma, a menos que
                                                        se trate de uma violação
                                                        direta da nossa marca.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        c. Tarefas relacionadas:
                                                    </div>

                                                    <div>
                                                        <br />
                                                        *1. É spam.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        *2. Nudez ou atividade
                                                        sexual.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        *3. Símbolos ou discurso
                                                        de ódio.
                                                    </div>

                                                    <div>
                                                        <br />
                                                        *4. Symbols or hate
                                                        speech.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *5. Informação falsa.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *6. Golpe ou fraude.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *7. Scam or Fraud.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *8. Bullyng ou assédio.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *9. Violação de
                                                        propriedade intelectual.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *10. Suicidio ou
                                                        automutilação. .
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *11. Venda de produtos
                                                        regulamentados ou
                                                        ilícitos.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *12. Distúrbios
                                                        alimentares.
                                                    </div>
                                                    <div>
                                                        <br />
                                                        *13. Outra coisa.
                                                    </div>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Card>
                        </Container>
                    </section>
                </main>
                <SimpleFooter />
            </>
        );
    }
}

export default TermOfUseCustomer;
